export const FEATURES = ['terms', 'showLogo', 'unaHideNotifyOperator'] as const;
export type FeatureName = (typeof FEATURES)[number];
export type FeaturesConfig = { [key in FeatureName]?: boolean };

export interface ConfigState {
  features?: FeaturesConfig;
  analytics?: {
    gtmContainerId: string;
  };
  keycloakServerUrl?: string;
}

export const initialState: ConfigState = {
  features: {
    showLogo: true,
    unaHideNotifyOperator: false,
  },
  analytics: {
    gtmContainerId: '',
  },
};
